/** @format */

.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #ff6536;
  /* Dark Green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinloader 2s linear infinite;
  padding: 12px;
}

.loader img {
  height: 120px;
  width: 120px;
  object-fit: contain;
  animation: spinlogo 2s linear infinite;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
